<template>
  <tr class="library-item-tr f-thead">
    <template v-if="game">
      <td class="draghandle" :class="disableReorder ? '-disabled' : ''">
        <span v-if="ownership.hasPriceChanges || hasRecentPriceChange" class="colorindicator">&nbsp;</span>
        <UIIcon class="w2 fill-inherit" name="menu" />
      </td>
      <td class="imagecell dn dtc-m" style="max-width: 150px; min-width: 100px; width: 10%;">
        <div class="badge absolute right-0 z-5 f-tiny white mt3 flex flex-column items-end" style="margin-top:1rem;">
          <span v-if="ownership.bundle" class="bundlebadge bg-red ph2 pv1 db mb2">{{ $t('In bundle') }}</span>
        </div>
        <div class="image">
          <RatioContainer class="ratiocontainer" :ratio="46.5863">
            <LazyImg
              class="posterimg absolute w-100 h-100"
              :style="{ opacity: loading ? 0.5 : 1 }"
              :src="game.imageUrl"
              :srcset="game.imageSrcSet"
              :placeholder="game.imageSvgUrl"
              :alt="game.title"
            />
          </RatioContainer>
        </div>
      </td>
      <td class="titlecell">
        <div>
          <VisibilityObserver @visible="$emit('visible', game)" :once="true" />
          <h4
            class="title f-header nowrap dib truncate slate w-100"
            :style="{ opacity: loading ? 0.5 : 1 }"
          >{{ game.title }}</h4>
          <h4
            class="manuallyadded db dn-m f-thead"
            v-if="game.manuallyAdded"
          >{{ $t('Manually Added Content') }}</h4>
          <h4 class="currentmonthcost db dn-m" v-else-if="game.license && game.license.upcomingInvoiceAmount">
            <span class="f-thead dib v-mid mr1">
              <template v-if="!ownership.isFree">
                <template v-if="game.license.upcomingInvoiceAmount">
                  {{ game.license.upcomingInvoiceAmount.displayValue }}
                  <span
                    class="f-thead ttu dib v-mid"
                  >USD</span>
                </template>
                <span class="f-thead" v-else>{{ $t('Unknown') }}</span>
              </template>
              <template v-else>{{ $t('Free') }}</template>
            </span>
          </h4>
          <h4 class="currentmonthcost db dn-m f-thead" v-else>{{ $t('Unknown') }}</h4>
          <router-link
            v-if="ownership.hasPriceChanges || hasRecentPriceChange"
            class="pricechangenotification link-style blue db pr2"
            :to="{ name: 'content-library-show', params: { gameId: game.id } }"
          >
            <span
              v-if="ownership.hasPriceChanges && hasRecentPriceChange"
            >{{ $t('Price changes & new price') }}</span>
            <span v-else-if="ownership.hasPriceChanges">{{ $t('Price changes') }}</span>
            <span v-else-if="hasRecentPriceChange">{{ $t('New price') }}</span>
          </router-link>
          <UIButton
            warning
            class="pendingeulanotification warning mt2 pa0"
            @click="openEULAModal(ownership)"
            icon="alert-triangle-fill"
            v-if="ownership.operatorLicense && (ownership.operatorLicense.promptForCurrentLicense || ownership.operatorLicense.promptForUpcomingLicense)"
          >{{ $t('Pending EULA Approval') }}</UIButton>
          <UIButton
            warning
            class="installerrorwarning warning mt2"
            :to="{ name: 'content-station-management', params: { gameId: game.id } }"
            icon="alert-triangle-fill"
            v-if="game.installErrorMessage"
          >{{ game.installErrorMessage }}</UIButton>
          <UIButton
            warning
            class="transitioncdswarning warning mt2"
            :to="{ name: 'content-station-management', params: { gameId: game.id } }"
            icon="alert-triangle-fill"
            v-if="game.cdsTransitionError"
          >{{ $t('Available on Content Distribution System') }}</UIButton>
        </div>
      </td>
      <template v-if="loading">
        <td colspan="3" class="tr dn dtc-m">
          <h1 class="loading bg-near-white pa2 w-60 dib">&nbsp;</h1>
        </td>
      </template>
      <template v-else-if="game.license">
        <td class="tc w-1 dn dtc-m">
          <h4 class="currentmonthcost" v-if="ownership.currentPrice">
            <span class="f-header slate flex justify-end items-center mr1">
              <template v-if="ownership.currentPrice.priceMinute">
                <template v-if="game.license.upcomingInvoiceAmount">
                  <span class>{{ game.license.upcomingInvoiceAmount.displayValue }}</span>
                  <span class="f-thead ttu ml2" style="line-height:1px;">USD</span>
                </template>
                <span class="f-thead" v-else>{{ $t('Unknown') }}</span>
              </template>
              <template v-else>{{ $t('Free') }}</template>
            </span>
          </h4>
          <h4 class="currentmonthcost f-thead" v-else>{{ $t('Unknown') }}</h4>
        </td>
        <td class="tc w-1 dn dtc-m">
          <h4
            class="currentmonthusage f-thead"
            v-if="game.license.currentTotalUsage"
          >{{ game.license.currentTotalUsage.displayValue }} min</h4>
          <h4 class="currentmonthusage f-thead" v-else>{{ $t('Unknown') }}</h4>
        </td>
        <td class="tc w-1 dn dtc-m">
          <h4
            class="installed f-thead"
            v-if="game.stationsAvailable !== null"
          >{{ game.stationsInstalled }}/{{ game.stationsAvailable}}&nbsp;{{ $tc('Station', 2) }}</h4>
          <h4 class="installed f-thead" v-else>{{ $t('Unknown') }}</h4>
        </td>
      </template>
      <template v-else-if="game.manuallyAdded">
        <td colspan="3" class="tr dn dtc-m">{{ $t('Manually Added Content') }}</td>
      </template>
      <td class="editcell">
        <UIButton
          tiny
          icon="edit"
          :to="{ name: 'content-library-show', params: { gameId: game.id } }"
          class="editbtn ml-auto"
        >{{ $t('Edit') }}</UIButton>
      </td>
    </template>
  </tr>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import RatioContainer from '@/core/shared/components/utils/RatioContainer';
import LazyImg from '@/core/shared/components/utils/LazyImg';
import EULAMixin from '@/content/shared/EULAMixin';
import VisibilityObserver from '@/core/components/utils/VisibilityObserver';
import { hasPriceRecentlyChanged } from '@/core/helpers/FormatHelpers';

@Component({
  components: {
    UIButton,
    UIIcon,
    LazyImg,
    RatioContainer,
    VisibilityObserver,
  },
  props: {
    game: { required: true },
    disableReorder: { type: Boolean },
    loading: { type: Boolean },
  },
})
export default class LibraryItemTr extends mixins(EULAMixin) {
  get ownership () {
    return (this.game && this.game.ownership) || {};
  }

  get hasRecentPriceChange () {
    const { minutePrice, monthlyPrice, yearlyPrice } = this.ownership;
    return [minutePrice, monthlyPrice, yearlyPrice].some(price => price && price.current && hasPriceRecentlyChanged(price.current));
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";
.library-item-tr {
  --borderWidth: 6px;
  @media (--tablet) {
    --borderWidth: 10px;
  }
  & .w-1 {
    width: 1px;
  }
  & >>> .w2 {
    width: 2em;
  }
  & .currentmonthcost {
    white-space: nowrap;
  }
  & .colorindicator {
    background: var(--colorInfo);
    position: absolute;
    top: 0;
    left: calc(-1 * var(--borderWidth));
    bottom: 0;
    border-top-left-radius: var(--radius);
    border-bottom-left-radius: var(--radius);
    width: 6px;
    @media (--tablet) {
      width: 10px;
    }
  }
  & .pricechangenotification {
  }
  border-collapse: separate;
  border-spacing: var(--borderWidth);
  border: 0;
  & td,
  & th {
    vertical-align: middle;
    padding: 12px 2px;
    @media (--tablet) {
      padding: 8px 5px;
    }
    position: relative;
  }
  & td {
    &:before {
      content: "";
      top: 0;
      left: calc(-1 * var(--borderWidth));
      width: calc(100% + 1px + var(--borderWidth));
      height: 100%;
      position: absolute;
      @apply --panelDropShadow;
      z-index: -2;
      background-color: var(--colorWhite);
    }
    &:after {
      content: "";
      top: 0;
      left: calc(-1 * var(--borderWidth));
      width: calc(100% + 1px + var(--borderWidth));
      height: 100%;
      position: absolute;
      z-index: -1;
      background-color: var(--colorWhite);
    }
    &:first-child:after,
    &:first-child:before {
      border-top-left-radius: var(--radius);
      border-bottom-left-radius: var(--radius);
    }
    &:last-child:after,
    &:last-child:before {
      left: calc(-1 * var(--borderWidth));
      width: calc(100% + 2 * var(--borderWidth));
      border-top-right-radius: var(--radius);
      border-bottom-right-radius: var(--radius);
    }
    & .bundlebadge {
      box-shadow: 0 0 0.4rem 0 rgba(0, 0, 0, 0.25);
      z-index: 500;
    }
  }
  & .editcell {
    padding: 0 var(--spacingXS);
    width: 1px;
    white-space: nowrap;
    @media (--tablet) {
      padding: 0 var(--spacingMd);
    }
  }
  & .draghandle {
    cursor: grab;
    position: relative;
    width: 42px;
    text-align: center;
    fill: var(--colorFjord);
    transition: fill 0.4s ease-in-out;
    &.-disabled {
      cursor: not-allowed;
      fill: var(--colorGeyser);
    }
  }
  & .titlecell {
    max-width: 13vw;
  }
  & .warning {
    display: block;
    text-align: left;
  }
  & .loading {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loadingPlaceholder;
    animation-timing-function: linear;
    background: #f6f6f6;
    background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
    background-size: 400px 100%;
  }
  & .currentmonthusage {
    position: relative;
    &:after {
      content: "";
      z-index: 5;
      position: absolute;
      width: 100%;
      height: 140%;
      top: -20%;
      left: 0px;
      border: 1px solid var(--colorGeyser);
      border-width: 0 1px;
    }
  }
}
@keyframes loadingPlaceholder {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}
</style>
